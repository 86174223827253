import { SendTx } from "../components/SendTx";
// import confetti from "canvas-confetti";
export const CongratsView = () => {
  // confetti({
  //   particleCount: 100,
  //   spread: 70,
  //   origin: { y: 0.6 },
  // });
  return (
    <div>
      <h3>
        Congratulations! 🎉
        <br />
      </h3>
      <h4>
        Stelo will open on every transaction, BEFORE they reach your wallet.
      </h4>
      <video autoPlay muted playsInline style={{ width: "80%" }} src="Stelo_Website_main_Animation.webm" />
    </div>
  );
};
