import React, { useEffect, useState } from "react";
import { useSendTransaction } from "wagmi";
import { usePrepareSendTransaction } from "wagmi";
import { LoadableButton, LoadableButtonStates } from "./LoadableButton";

type SendTxProps = {
  onReject?: () => void;
  isLoading: boolean;
  buttonState: LoadableButtonStates;
  sendTx: (() => Promise<any>) | undefined;
};

export function SendTx({
  onReject,
  isLoading,
  buttonState,
  sendTx,
}: SendTxProps) {
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        sendTx?.()
          .then(console.log)
          .catch(() => {
            onReject?.();
          });
      }}
    >
      <LoadableButton
        disabled={isLoading}
        state={buttonState}
        className="sendEthButton btn"
      >
        Send 0 ETH
      </LoadableButton>
    </form>
  );
}
