import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useAccount } from "wagmi";

export const ConnectWalletView = () => {
  const { isConnected } = useAccount();

  return (
    <div className="eth-buttons">
      {isConnected ? (
        <h3>Want to switch?</h3>
      ) : (
        <h3>First, let's connect. 🤝</h3>
      )}
      <ConnectButton />
    </div>
  );
};
