import React, { CSSProperties } from "react";

export const BrowserLayout: React.FC<{
  url?: string;
  style?: CSSProperties;
  children?: JSX.Element;
}> = ({ url = "https://onboarding.stelolabs.com", style, children }) => {
  return (
    <div style={style} className="browser-view">
      <div className="row">
        <div className="column left">
          <span className="dot" style={{ background: "#ED594A" }}></span>
          <span className="dot" style={{ background: "#FDD800" }}></span>
          <span className="dot" style={{ background: "#5AC05A" }}></span>
        </div>
        <div className="column middle">
          <input type="text" value={url}></input>
        </div>
        <div className="column right">
          <img
            className="stelo-icon"
            src="/icon48.png"
            width="16"
          ></img>
          <div style={{ float: "right" }}>
            <span className="bar"></span>
            <span className="bar"></span>
            <span className="bar"></span>
          </div>
        </div>
      </div>

      <div style={{ flexGrow: 1 }}>{children}</div>
    </div>
  );
};
