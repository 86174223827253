import "./App.css";
import "@rainbow-me/rainbowkit/styles.css";
import { lightTheme, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { wagmiClient, chains } from "./wagmi";
import { WagmiConfig } from "wagmi";
import { BrowserLayout } from "./layouts/BrowserLayout";
import { Nav } from "./components/Nav";
import { OnboardingView } from "./views/OnboardingView";

function App() {
  return (
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider chains={chains}     theme={lightTheme({
      accentColor: '#7A75FF',
    })} coolMode>
        <div className="App">
          <Nav></Nav>
          <h1 style={{ margin: "52px", fontFamily: "SpaceGrotesk" }}>
            Give Stelo a Spin
          </h1>
          <header className="App-header">
            <div className="outer-container box inset">
              <BrowserLayout style={{ marginBottom: "48px" }}>
                <OnboardingView></OnboardingView>
              </BrowserLayout>
            </div>
          </header>
        </div>
      </RainbowKitProvider>
    </WagmiConfig>
  );
}

export default App;
