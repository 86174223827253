import React from "react";

export const Nav = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        marginTop: "10px",
        height: "50px",
      }}
    >
      <div className="box inset">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            fontFamily: "Source Sans Pro",
            fontWeight: 700,
            fontSize: "26px",
          }}
        >
          <a
            href="https://stelolabs.com"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: "black",
              textDecoration: "none",
            }}
          >
            <img
              src="/stelo_brand_black.svg"
              style={{ marginRight: "11.5px", width: "100px", height: "35px" }}
            ></img>
          </a>
        </div>

    
      </div>
    </div>
  );
};
