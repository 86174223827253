import { configureChains, createClient, mainnet } from "wagmi";

import { getDefaultWallets } from "@rainbow-me/rainbowkit";
import { alchemyProvider } from "wagmi/providers/alchemy";

export const { chains, provider } = configureChains(
  [mainnet],
  [alchemyProvider({ apiKey: process.env.REACT_APP_ALCHEMY_API_KEY || "" })]
);

const { connectors } = getDefaultWallets({
  appName: "Explore by Stelo",
  chains,
});
export const wagmiClient = createClient({
  connectors,
  provider,
});
