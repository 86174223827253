import { useEffect, useState } from "react";
import { usePrepareSendTransaction, useSendTransaction } from "wagmi";
import { LoadableButtonStates } from "../components/LoadableButton";
import { SendTx } from "../components/SendTx";

const STELO_DOT_ETH = "0x029559467c12Ac8173c387da6e4C66e4A6C8aF61";

type SendTxViewProps = {
  onReject?: () => void;
};
export const SendTxView = ({ onReject }: SendTxViewProps) => {
  const { config } = usePrepareSendTransaction({
    request: {
      to: STELO_DOT_ETH,
      gasLimit: 0x0,
      gasPrice: 0x0,
    },
  });
  const { data, isLoading, isError, isSuccess, sendTransactionAsync, status } =
    useSendTransaction(config);
  console.log(data, status);
  const [buttonState, setButtonState] =
    useState<LoadableButtonStates>("DEFAULT");

  useEffect(() => {
    if (isLoading) {
      setButtonState("LOADING");
    } else if (isError) {
      setButtonState("DEFAULT");
    } else if (isSuccess) {
      setButtonState("SUCCESS");
      setTimeout(() => {
        setButtonState("DEFAULT");
      }, 1000);
    }
  }, [isLoading, isSuccess, isError]);

  return (
    <div>
      {isLoading ? (
        <>
          <h3>Woohoo! Check out those risk factors. ‍💫</h3>
          <h4>Reject the tx in your wallet to move on.</h4>
        </>
      ) : (
        <>
        <h3>Next, let's try a test transaction.</h3>
        <h3>Make sure to REJECT the transaction in your wallet</h3>
        </>
      )}
      <p></p>
      <div className="eth-buttons">
        <SendTx
          onReject={onReject}
          isLoading={isLoading}
          buttonState={buttonState}
          sendTx={sendTransactionAsync}
        />
      </div>
    </div>
  );
};
