import React from "react";
import { Spinner } from "./Spinner";

export const LOADABLE_BUTTON_STATES = ["DEFAULT", "LOADING", "SUCCESS"];
export type LoadableButtonStates = typeof LOADABLE_BUTTON_STATES[number];

type ButtonProps = React.HTMLProps<HTMLButtonElement> & {
  style?: React.CSSProperties;
  state?: LoadableButtonStates;
  type?: "button" | "submit" | "reset" | undefined;
};

export const LoadableButton = ({
  style,
  children,
  disabled,
  state = "DEFAULT",
  type,
  className,
  ...rest
}: ButtonProps) => {
  const self = React.useRef(null);

  function getContents(status: LoadableButtonStates) {
    switch (status) {
      case "DEFAULT":
        return children;
      case "LOADING":
        return <Spinner />;
      case "SUCCESS":
        return <span>check</span>;
      default:
        return children;
    }
  }
  return (
    <button
      ref={self}
      type={type}
      disabled={disabled}
      className={className}
      {...rest}
    >
      {getContents(state)}
    </button>
  );
};
