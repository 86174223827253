import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useAccount, useConnect, useDisconnect } from "wagmi";
import { CongratsView } from "./CongratsView";
import { ConnectWalletView } from "./ConnectWalletView";
import { SendTxView } from "./SendTxView";

const VIEWS = ["CONNECT", "SEND", "CONGRATS"];
type View = typeof VIEWS[number];

export const OnboardingView = () => {
  const { isConnected, address } = useAccount();
  const [hasRejected, setHasRejected] = useState<boolean>(false);
  const [activeView, setActiveView] = useState<View>("CONNECT");
  const Inner = (() => {
    if (activeView == "CONNECT") {
      return <ConnectWalletView />;
    } else if (activeView == "SEND") {
      return (
        <SendTxView
          onReject={() => {
            setHasRejected(true);
          }}
        />
      );
    } else {
      return <CongratsView />;
    }
  })();

  useEffect(() => {
    setHasRejected(false);
  }, [address]);
  useEffect(() => {
    if (isConnected) {
      setActiveView("SEND");
    }
    if (isConnected && hasRejected) {
      setActiveView("CONGRATS");
    }
    if (!isConnected) {
      setActiveView("CONNECT");
    }
  }, [isConnected, hasRejected]);

  return (
    <div
      style={{
        display: "flex",
        position: "relative",
        height: "100%",
        alignItems: "flex-start",
      }}
    >
      <div
        style={{
          position: "relative",
          paddingTop: "48px",
          paddingLeft: "5%",
          backgroundColor: "#EAEAFF",
          width: "35%",
          height: "100%",
        }}
      >
        <p
          onClick={() => {
            setActiveView("CONNECT");
          }}
          className={clsx(
            "onboardingStage",
            activeView == "CONNECT" && "active"
          )}
        >
          1. Connect Your Wallet
        </p>
        <p
          onClick={() => {
            if (isConnected) {
              setActiveView("SEND");
            }
          }}
          className={clsx(
            "onboardingStage",
            !isConnected && "disabled",
            activeView == "SEND" && "active"
          )}
        >
          2. Send a Transaction
        </p>
        <p
          onClick={() => {
            if (hasRejected) {
              setActiveView("CONGRATS");
            }
          }}
          className={clsx(
            "onboardingStage",
            (!isConnected || !hasRejected) && "disabled",
            activeView == "CONGRATS" && "active"
          )}
        >
          3. You're all Set
        </p>
      </div>
      <div
        style={{
          display: "flex",
          position: "relative",
          height: "100%",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {Inner}
      </div>
    </div>
  );
};
